import {
  Container,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { T } from "react-targem";

import About from "./About";
import { styles } from "./AboutTemplate.styles";
import Hiring from "./Hiring";
import OurGoal from "./OurGoal";
import PresidentInfo from "./PresidentInfo";
import TeamPhotos from "./TeamPhotos";

interface AboutTemplateProps extends WithStyles<typeof styles> {}

class AboutTemplate extends React.Component<AboutTemplateProps> {
  public render() {
    const { classes } = this.props;

    return (
      <>
        <Container className={classes.container}>
          <Typography variant="h2" className={classes.title}>
            <T message="About us" />
          </Typography>
          <PresidentInfo className={classes.presidentInfo} />
          <About className={classes.about} />
          <OurGoal className={classes.ourGoal} />
        </Container>
        <TeamPhotos className={classes.teamPhotos} />
        <Container className={classes.container}>
          <Hiring className={classes.hiring} />
        </Container>
      </>
    );
  }
}

export default withStyles(styles)(AboutTemplate);
