import { Theme } from "@material-ui/core";

import { createStylesWithProps } from "@src/utils/styles";

import { ManagerInfoProps } from "./ManagerInfo";

const createStyles = createStylesWithProps<ManagerInfoProps>();

const maxPhotoSize = "calc(100vw - 32px)";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    photoContainer: {
      position: "relative",
      "&::before": {
        content: "''",
        width: "280px",
        height: "280px",
        position: "absolute",
        top: theme.spacing(2),
        backgroundColor: theme.palette.secondary.light,
        borderRadius: "50%",
        left: ({ isBackgroundToRight }) =>
          isBackgroundToRight ? "26px" : "-26px",
        [theme.breakpoints.down("xs")]: {
          width: "200px",
          height: "200px",
          left: ({ isBackgroundToRight }) =>
            isBackgroundToRight ? theme.spacing(3) : theme.spacing(-3),
        },
      },
    },
    photo: {
      width: "280px",
      height: "280px",
      borderRadius: "50%",
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down("xs")]: {
        width: "200px",
        height: "200px",
        maxWidth: maxPhotoSize,
        maxHeight: maxPhotoSize,
      },
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    subTitle: {
      marginBottom: theme.spacing(3),
    },
    button: {
      margin: theme.spacing(1, -1, 0),
    },
    list: {
      margin: 0,
      padding: theme.spacing(1, 0, 0, 0),
      listStyleType: "none",
      marginLeft: theme.spacing(3),
    },
    listItem: {
      display: "flex",
      alignItems: "center",
      "&:before": {
        content: `"•"`,
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(2),
      },
    },
  });
