import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    imageContainer: {
      position: "relative",
    },
    teamPhoto: {
      width: "100%",
    },
    title: {
      paddingBottom: theme.spacing(6),
    },
    greenDots: {
      height: "512px",
      maxHeight: "80vw",
      position: "absolute",
      bottom: theme.spacing(-27),
      right: theme.spacing(17),
      transform: "rotateZ(90deg)",
      [theme.breakpoints.down("xs")]: {
        bottom: "-52%",
        right: 0,
        height: "auto",
      },
    },
    teamInfo: {
      marginTop: theme.spacing(10),
    },
  });
