// Pages cannot be exported using `export { default } from "..."`
// shorthand due to Gatsby bug https://github.com/gatsbyjs/gatsby/issues/12384

import AboutPage from "@src/templates/AboutPage";
export default AboutPage;
import { graphql } from "gatsby";

export const teamPhoto = graphql`
  fragment teamPhoto on File {
    childImageSharp {
      fluid(maxWidth: 880) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query AboutPageQuery {
    teamPhoto2: file(relativePath: { eq: "images/team_photo2.png" }) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    teamPhotos: allFile(
      filter: { relativePath: { regex: "^images/teamPhotos/" } }
      sort: { fields: relativePath }
    ) {
      edges {
        node {
          ...teamPhoto
        }
      }
    }
    presidentPhoto: file(relativePath: { eq: "images/president_photo.png" }) {
      childImageSharp {
        fluid(maxWidth: 280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
