import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    title: {
      textAlign: "center",
      marginBottom: theme.spacing(10),
    },
    gallery: {
      margin: "0 auto",
    },
    photosWrapper: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      margin: theme.spacing(0, -1),
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
      },
    },
    teamPhoto: {
      width: "calc(100vw /3)",
      maxWidth: "475px",
      margin: theme.spacing(0, 1),
      [theme.breakpoints.down("sm")]: {
        width: "720px",
        maxWidth: "calc(100vw - 80px)",
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100vw",
      },
    },
    greenDots: {
      width: "460px",
      maxWidth: "calc(100% / 3 - 64px)",
      position: "absolute",
      bottom: theme.spacing(-7),
      left: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        maxWidth: "360px",
        left: 0,
      },
    },
    upperGreenDots: {
      top: theme.spacing(-7),
    },
  });
