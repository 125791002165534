import { Typography, WithStyles, withStyles } from "@material-ui/core";
import React from "react";
import { T } from "react-targem";

import { styles } from "./OurGoal.styles";

export type AboutTemplateProps = WithStyles<typeof styles> & {
  className?: string;
};

class OurGoal extends React.PureComponent<AboutTemplateProps> {
  public render() {
    const { classes, className } = this.props;

    return (
      <div className={className}>
        <Typography variant="h3" className={classes.title}>
          <T message="Our goal" />
        </Typography>
        <Typography variant="body1" paragraph>
          <T message="Trucknet aims to build unique business ecosystems. Its software platform integrates with existing telematics and TMS systems, thereby providing transportation companies with the opportunity for sharing economy, improving visibility, and using electronic documents and payment solutions." />
        </Typography>
        <Typography variant="body1" paragraph>
          <T message="Trucknet's platform enables transportation and logistics companies to minimize the empty truck rides of their fleet by interfacing and negotiating available rides with other (transportation and logistics) companies." />
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(OurGoal);
