import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      margin: "0 auto",
      maxWidth: "800px",
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(20),
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(10),
      },
    },
    title: {
      textAlign: "center",
    },
    presidentInfo: {
      marginTop: theme.spacing(10),
    },
    about: {
      marginTop: theme.spacing(20),
    },
    ourGoal: {
      marginTop: theme.spacing(20),
    },
    teamPhotos: {
      marginTop: theme.spacing(20),
    },
    hiring: {
      marginTop: theme.spacing(20),
    },
  });
