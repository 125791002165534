import { Typography, WithStyles, withStyles } from "@material-ui/core";
import cn from "classnames";
import GatsbyImage from "gatsby-image";
import React from "react";

import { GatsbyQueriedImage } from "@src/types";

import { styles } from "./ManagerInfo.styles";

export type ManagerInfoProps = {
  className?: string;
  name: React.ReactNode;
  job: React.ReactNode;
  children: React.ReactNode;
  image: GatsbyQueriedImage;
  isBackgroundToRight?: boolean;
};

const ManagerInfo = (props: ManagerInfoProps & WithStyles<typeof styles>) => {
  const { classes, className } = props;

  return (
    <div className={cn(className, classes.container)}>
      <div className={classes.photoContainer}>
        <GatsbyImage
          fluid={props.image.childImageSharp.fluid}
          className={classes.photo}
        />
      </div>
      <div>
        <Typography variant="h4" className={classes.title}>
          {props.name}
        </Typography>
        <Typography variant="subtitle1" className={classes.subTitle}>
          {props.job}
        </Typography>
        {props.children}
      </div>
    </div>
  );
};

export default withStyles(styles)(ManagerInfo);
