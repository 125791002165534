import { Typography } from "@material-ui/core";
import React from "react";
import { T } from "react-targem";

import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { AboutPageProps } from "@src/templates/AboutPage";

// tslint:disable-next-line: no-relative-imports
import ManagerInfo from "../ManagerInfo";

interface Props extends WithPageData<AboutPageProps> {
  className?: string;
}

const PresidentInfo = (props: Props) => {
  return (
    <ManagerInfo
      className={props.className}
      image={props.pageData.presidentPhoto}
      name={<T message="Hanan Friedman" />}
      job={<T message="Founder and President" />}>
      <Typography variant="body1" paragraph>
        <T message="Hanan Friedman established Trucknet Enterprise in 2016 and together with his Information Technology (IT) team developed the company's unique automatic platform using a smart algorithm.  He has been invited to present Trucknet's intelligent transportation platform in two annual UNFCCC Climate Change Conferences in the past years. " />
      </Typography>
      <Typography variant="body1" paragraph>
        <T message="As an environmental and agricultural expert, Hanan gained extensive operational experience in food and marketing logistics.  In his previous position at the Israel Ministry of Defense, his responsibilities included advancing privatization of the food industry and food logistics for the army (IDF). Hanan served as project manager for the construction of numerous solar power plants in the southern part of the country. He is an active participant in the Fuel Choices and Smart Mobility Initiative of the Prime Minister's office. " />
      </Typography>
      <Typography variant="body1" paragraph>
        <T
          message={`Hanan served as a commander in the Paratroopers unit in the IDF. Following his army service, he obtained a Business Administration degree.  In addition to his professional activities, he supports "Larger than Life", a public non-profit organization assisting families facing pediatric cancer. Hanan also lectures in the framework of "Unistream" – a non-profit that empowers youth and young adult entrepreneurship in the periphery.`}
        />
      </Typography>
    </ManagerInfo>
  );
};

export default withPageData(PresidentInfo);
