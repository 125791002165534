import {
  Container,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import cn from "classnames";
import GatsbyImage from "gatsby-image";
import React from "react";
import { T } from "react-targem";

import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { AboutPageProps } from "@src/templates/AboutPage";

import GreenDots from "@src/components/GreenDots";
import { styles } from "./TeamPhotos.styles";

interface TeamPhotosProps
  extends WithStyles<typeof styles>,
    WithPageData<AboutPageProps> {
  className?: string;
}

class TeamPhotos extends React.PureComponent<TeamPhotosProps> {
  public render() {
    const { pageData, classes, className } = this.props;
    const { teamPhotos } = pageData;

    return (
      <div className={cn(className, classes.container)}>
        <Container>
          <Typography variant="h3" className={classes.title}>
            <T message="Meet our team" />
          </Typography>
        </Container>
        <div className={classes.gallery}>
          <div className={classes.photosWrapper}>
            <GreenDots
              className={cn(classes.greenDots, classes.upperGreenDots)}
              horizontal
            />
            {teamPhotos.edges.map(({ node }, index) => (
              <GatsbyImage
                key={index}
                fluid={node.childImageSharp.fluid}
                className={classes.teamPhoto}
              />
            ))}
            <GreenDots className={classes.greenDots} horizontal />
          </div>
        </div>
      </div>
    );
  }
}

export default withPageData(withStyles(styles)(TeamPhotos));
