import { Typography, WithStyles, withStyles } from "@material-ui/core";
import GatsbyImage from "gatsby-image";
import React from "react";
import { T } from "react-targem";

import GreenDots from "@src/components/GreenDots";
import { withPageData, WithPageData } from "@src/contexts/PageDataContext";
import { AboutPageProps } from "@src/templates/AboutPage";

import { styles } from "./About.styles";

interface AboutTemplateProps
  extends WithStyles<typeof styles>,
    WithPageData<AboutPageProps> {
  className?: string;
}

class About extends React.PureComponent<AboutTemplateProps> {
  public render() {
    const { pageData, classes, className } = this.props;
    const { teamPhoto2 } = pageData;

    return (
      <div className={className}>
        <Typography variant="h3" className={classes.title}>
          <T message="About Trucknet" />
        </Typography>
        <div className={classes.imageContainer}>
          <GatsbyImage
            fluid={teamPhoto2.childImageSharp.fluid}
            className={classes.teamPhoto}
          />
          <GreenDots className={classes.greenDots} />
        </div>
        <div className={classes.teamInfo}>
          <Typography variant="body1" paragraph>
            <T message="Trucknet provides fleet management companies with a cloud-based transport optimization platform that delivers smart freight exchange to improve efficiency and profitability, while reducing environmental impact. " />
          </Typography>
          <Typography variant="body1" paragraph>
            <T message="The system consists of the following components: The Trucknet platform (automatically matches empty vehicles to subcontractor requests from other companies), TMS (internal schedule software for transportation companies), Driver Interface (a mobile application for the driver), Tracker (a mobile application for end-to-end delivery, currently in development), Trucknet Telematics (a central interface for different telematics systems, in development)." />
          </Typography>
        </div>
      </div>
    );
  }
}

export default withPageData(withStyles(styles)(About));
