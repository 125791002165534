import React from "react";

import SeoHead from "@src/components/SeoHead";
import IndexLayout from "@src/layouts/IndexLayout";
import { GatsbyQueriedImagesList, PageWithImagesProps } from "@src/types";
import { t } from "@src/utils/translations";

import AboutTemplate from "./AboutTemplate";

export type AboutPageProps = PageWithImagesProps<
  "teamPhoto1" | "teamPhoto2" | "ceoPhoto" | "presidentPhoto",
  { teamPhotos: GatsbyQueriedImagesList }
> & {};

class AboutPage extends React.Component<AboutPageProps> {
  public render() {
    return (
      <IndexLayout {...this.props}>
        <SeoHead
          title={t("About us")}
          description={t(
            "Trucknet's platform enables transportation companies to minimize the empty truck rides of their fleet by interfacing and negotiating available rides with other companies",
          )}
          keywords={[t("founder"), t("our goal"), t("our team")]}
        />
        <AboutTemplate />
      </IndexLayout>
    );
  }
}

export default AboutPage;
