import { Typography, WithStyles, withStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T } from "react-targem";

import ButtonLink from "@src/components/ButtonLink/ButtonLink";

import { styles } from "./Hiring.styles";

export type AboutTemplateProps = WithStyles<typeof styles> & {
  className?: string;
};

class Hiring extends React.PureComponent<AboutTemplateProps> {
  public render() {
    const { classes, className } = this.props;

    return (
      <div className={cn(className, classes.textBlockCenter)}>
        <Typography variant="h4" className={classes.paragraph}>
          <T message="Looking for a job? We are hiring!" />
        </Typography>
        <Typography variant="body1" className="tn-non-responsive">
          <T message="Click the button below and check which jobs are open!" />
        </Typography>
        <ButtonLink
          to="/careers"
          className={classes.button}
          variant="contained"
          color="secondary">
          <T message="We are hiring!" />
        </ButtonLink>
      </div>
    );
  }
}

export default withStyles(styles)(Hiring);
