import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    paragraph: {
      paddingBottom: theme.spacing(2),
    },
    textBlockCenter: {
      maxWidth: "840px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    button: {
      margin: theme.spacing(6, 0, 5, 0),
    },
  });
